@mixin animateEaseInOut($duration: 0.3s) {
    transition: all $duration ease-in-out;
}

@mixin dropShadow {
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1); 
    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
}

@mixin flex-row-fullwidth {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
}

@mixin flex-column($justify: flex-start, $align: center, $gap: 20px) {
    display: flex;
    flex-direction: column;
    justify-content: $justify;
    align-items: $align;
    gap: $gap;
}

@mixin flex-row($justify: flex-start, $align: center, $gap: 20px, $wrap: wrap) {
    display: flex;
    flex-direction: row;
    justify-content: $justify;
    align-items: $align;
    gap: $gap;
    flex-wrap: $wrap;
}