@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600&display=swap");
* {
  box-sizing: border-box;
}

body, html {
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  line-height: 1.25;
  background-color: #ffffff;
  color: #333333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: #000000;
}

h1 {
  font-size: 2.5rem;
}

a {
  text-decoration: none;
  color: #000000;
}
a:hover {
  text-decoration: underline;
  color: #4c4edd;
}

.small {
  font-size: 0.7rem;
  font-weight: 500;
  color: #5B6671;
}

a.branding {
  font-size: 1.75rem;
  font-weight: 500;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  color: #333333;
}
a.branding:hover {
  text-decoration: none;
}

figure.featuredMedia {
  width: 100%;
  padding-bottom: 55%;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  margin: 0;
}
figure.featuredMedia img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
figure.featuredMedia img:hover {
  transform: scale(1.01);
  transition: all 0.3s ease-in-out;
}
figure.featuredMedia .icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  background-color: #4c4edd;
  color: #ffffff;
  font-size: 0.9rem;
  line-height: 0.75rem;
  padding: 0.75rem;
}

figcaption {
  width: 100%;
  display: block;
  font-size: 0.8em;
  text-align: center;
  border-bottom: 1px solid #eef1f0;
  padding: 10px;
}

.socialShare-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  width: 100%;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #DDE3E1;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  gap: 20px;
  padding-top: 110px;
}

header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
  background-color: #ffffff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}
header .headerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1280px;
  min-height: 65px;
}
header .headerWrapper nav .icon {
  display: none;
}
header .headerWrapper nav ul {
  list-style-type: none;
}
header .headerWrapper nav ul li {
  display: inline;
  padding: 10px;
}
header .headerWrapper nav ul li a {
  font-size: 0.9rem;
  font-weight: 700;
}
header .headerWrapper nav ul li a:hover {
  text-decoration: none;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  min-height: 60vh;
  padding: 0 20px;
}
.page-container main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  width: 100%;
  max-width: 1280px;
}
.page-container main .posts-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}
.page-container main .posts-block .post {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  flex: 0 0 calc(33.3333% - 6.6666666667px);
  padding-bottom: 20px;
  position: relative;
}
.page-container main .posts-block .post figure.featuredMedia {
  padding-bottom: 66%;
}
.page-container main .posts-block .post.featured-post {
  flex: 0 0 100%;
}
.page-container main .posts-block .post.featured-post figure.featuredMedia {
  padding-bottom: 50%;
}
.page-container main .posts-block .post:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  display: block;
  width: 75px;
  height: 3px;
  border-radius: 3px;
  background-color: #0a1829;
  transition: all 0.3s ease-in-out;
}
.page-container main .posts-block .post:hover:after {
  width: 100px;
  transition: all 0.3s ease-in-out;
}
.page-container main .posts-block .post a {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  color: #000000;
}
.page-container main .posts-block .post a:hover {
  text-decoration: none;
}
.page-container main .posts-block .post a .taxonomy {
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: #5B6671;
}
.page-container main .posts-block .post a h2 {
  font-size: 1.25rem;
  padding: 0;
  margin: 0;
}
.page-container main .innerDetails {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #eef1f0;
  border-bottom: 1px solid #eef1f0;
  text-align: center;
  color: #5B6671;
  font-size: 0.85rem;
}
.page-container main .renderedContent {
  width: 50%;
  margin: 0 auto;
  line-height: 1.5;
  font-size: 1.15rem;
}
.page-container main .renderedContent a {
  color: #4c4edd;
}
.page-container main .renderedContent figure {
  margin: 0;
  padding: 0;
  width: 100% !important;
}
.page-container main .renderedContent figure img {
  width: 100%;
  height: auto;
}

.gallery {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}
.gallery a {
  display: flex;
  flex: 1 0 calc(33.33% - 20px);
  padding-bottom: 33.33%;
  position: relative;
  overflow: hidden;
}
.gallery a:nth-child(3n+1):last-child {
  flex: 1 0 100%;
  padding-bottom: 66%;
}
.gallery a img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  padding-top: 30px;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #000000;
  font-weight: 500;
}
.pagination a, .pagination span {
  padding: 0.5rem 0.75rem;
  line-height: 1rem;
  color: #0a1829;
}
.pagination a.activePage, .pagination a:hover, .pagination span.activePage, .pagination span:hover {
  text-decoration: none;
  background-color: #eef1f0;
  color: #0a1829;
  border-radius: 0.5rem;
}
.pagination .navButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  color: #000000;
}

.authorBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 500px;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #5B6671;
}
.authorBox .authorImage {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.7019607843);
  position: relative;
  overflow: hidden;
}
.authorBox .authorImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.authorBox p {
  margin: 0;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  background-color: #eef1f0;
  padding: 60px 20px;
}
footer .inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 1280px;
}
footer .inner .contacts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
footer .inner .contacts .branding {
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #DDE3E1;
}
footer #footerNav ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  list-style-type: none;
}
footer #footerNav ul li a:hover {
  text-decoration: none;
}
footer .copy {
  width: 100%;
  max-width: 1280px;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #DDE3E1;
  text-align: center;
}

.banner {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0 0;
  margin: 10px 0 0;
  border-top: 1px solid #DDE3E1;
}
.banner img {
  width: 100%;
  height: auto;
}
.banner .show-mobile {
  display: none;
}
.banner .show-desktop {
  display: block;
}
.banner:hover {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .App {
    padding-top: 100px;
  }
  figure.featuredMedia {
    padding-bottom: 66%;
  }
  header .headerWrapper {
    min-height: 55px;
  }
  header .headerWrapper a.branding {
    font-size: 1.15rem;
    line-height: 1.5rem;
  }
  header .headerWrapper nav .icon {
    display: block;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  header .headerWrapper nav .overlay {
    position: fixed;
    display: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #333333;
    opacity: 0;
    transition: opacity 1s ease-in-out, display 1s ease-in-out;
  }
  header .headerWrapper nav .overlay.active {
    display: block;
    opacity: 0.2;
    transition: opacity 1s ease-in-out, display 1s ease-in-out;
  }
  header .headerWrapper nav ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    position: fixed;
    opacity: 0;
    top: 0;
    right: -100vw;
    width: 100%;
    max-width: 500px;
    height: 100vh;
    background-color: #fafafa;
    z-index: 11;
    margin: 0;
    padding: 10px;
    transition: all 0.3s ease-in-out;
  }
  header .headerWrapper nav ul.active {
    opacity: 1;
    right: 0;
    transition: all 0.3s ease-in-out;
  }
  header .headerWrapper nav ul li {
    display: block;
    width: 80%;
    text-align: center;
    padding: 15px 0px;
    border-bottom: 1px solid #eef1f0;
  }
  header .headerWrapper nav ul li:last-of-type {
    border-bottom: none;
  }
  header .headerWrapper nav ul .closeButton {
    position: absolute;
    right: 20px;
    top: 22.5px;
  }
  .page-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 100%;
    min-height: 60vh;
    padding: 0 20px;
  }
  .page-container main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    width: 100%;
    max-width: 1280px;
  }
  .page-container main .posts-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
  }
  .page-container main .posts-block .post {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    flex: 1 0 calc(50% - 10px);
    padding-bottom: 20px;
    margin-bottom: 10px;
    position: relative;
  }
  .page-container main .posts-block .post.featured-post {
    flex: 0 0 100%;
  }
  .page-container main .posts-block .post.featured-post figure.featuredMedia {
    padding-bottom: 66%;
  }
  .page-container main .posts-block .post:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    display: block;
    width: 75px;
    height: 3px;
    border-radius: 3px;
    background-color: #0a1829;
    transition: all 0.3s ease-in-out;
  }
  .page-container main .posts-block .post:hover:after {
    width: 100px;
    transition: all 0.3s ease-in-out;
  }
  .page-container main .posts-block .post a {
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
  }
  .page-container main .posts-block .post a:hover {
    text-decoration: none;
  }
  .page-container main .posts-block .post a .taxonomy {
    font-size: 0.8rem;
    line-height: 1rem;
    color: #5B6671;
  }
  .page-container main .posts-block .post a h2 {
    font-size: 1rem;
    line-height: 1.25;
    padding: 0;
    margin: 0;
  }
  .page-container main .renderedContent {
    width: 70%;
    margin: 0 auto;
    font-size: 1.05rem;
  }
}
@media (max-width: 768px) {
  .banner .show-mobile {
    display: block;
  }
  .banner .show-desktop {
    display: none;
  }
  .page-container main {
    width: 100%;
  }
  .page-container main .content-wrapper {
    width: 100%;
  }
  .page-container main h1,
  .page-container main h2 {
    line-height: normal;
  }
  .page-container main h1 {
    font-size: 1.75rem;
  }
  .renderedContent {
    width: 100% !important;
    margin: 0;
  }
  .renderedContent figure {
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: auto;
  }
  .renderedContent figure img {
    width: 100%;
    height: auto;
  }
  .authorBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    max-width: 100%;
    text-align: center;
  }
  footer .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  footer .copy {
    margin-top: 0;
  }
  footer .footerNavigation {
    width: 100%;
  }
  footer .footerNavigation #footerNav ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
  }
  footer .footerNavigation #footerNav ul li a {
    font-size: 0.85rem;
  }
  footer .footerNavigation #footerNav ul li a:hover {
    text-decoration: none;
  }
}/*# sourceMappingURL=App.css.map */