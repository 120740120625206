@import "global_settings";
@import "mixins";

// Global settings

* {
    box-sizing: border-box;
}

body, html {
    font-family: $primaryFont;
    font-size: 18px;
    line-height: 1.25;
    background-color: $backgroundColor;
    color: $textColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    color: $primaryColor;
}

h1 {
    font-size: 2.5rem;
}

a {
    text-decoration: none;
    color: $primaryColor;

    &:hover {
        text-decoration: underline;
        color: $linkHoverColor;
    }
}

.small {
    font-size: 0.7rem;
    font-weight: 500;
    // text-transform: uppercase;
    color: $topBarMenuColor;
}

// Logo/Branding
a.branding {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: 0.125em;
    text-transform: uppercase;
    color: $textColor;

    &:hover {
        text-decoration: none;
    }
}

// Featured image styles
figure.featuredMedia {
    width: 100%;
    padding-bottom: 55%;
    position: relative;
    background-color: $secondaryColor;
    overflow: hidden;
    margin: 0;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        @include animateEaseInOut();

        &:hover {
            transform: scale(1.01);
            @include animateEaseInOut();
        }
    }

    .icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 2;
        background-color: $linkHoverColor;
        color: $secondaryColor;
        font-size: 0.9rem;
        line-height: 0.75rem;
        padding: 0.75rem;
    }
}
figcaption {
    width: 100%;
    display: block;
    font-size: 0.8em;
    text-align: center;
    border-bottom: 1px solid $lightGreyBackgound;
    padding: 10px;
}

// Social Share Styling

.socialShare-block {
    @include flex-row(center, center, 10px, nowrap);
    width: 100%;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid $platinum;
}

// Main App styles

.App {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    gap: 20px;
    padding-top: 110px;
}

// Header styles

header {
    @include flex-column(space-between, center, 10px);
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9;
    background-color: $backgroundColor;
    @include dropShadow();

    .headerWrapper {
        @include flex-row(space-between, center, 20px, wrap);
        width: 100%;
        max-width: $contentWidth;
        min-height: 65px;
    
        // Main navigation styles
        nav {

            .icon {
                display: none;
            }
    
            ul {
                list-style-type: none;
    
                li {
                    display: inline;
                    padding: 10px;
    
                    a {
                        font-size: 0.9rem;
                        font-weight: 700;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

// Page-Container styling

.page-container {
    @include flex-column(flex-start, center, 20px);
    width: 100%;
    min-height: 60vh;
    padding: 0 20px;

    main {
        @include flex-column(center, center, 0);
        width: 100%;
        max-width: $contentWidth;

        // Posts block styling
        .posts-block {
            @include flex-row(flex-start, stretch, 10px, wrap);
            width: 100%;

            .post {
                @include flex-column(flex-start, stretch, 10px);
                flex: 0 0 calc(33.3333% - ( 20px / 3 ));
                padding-bottom: 20px;
                position: relative;

                figure.featuredMedia {
                    padding-bottom: 66%;
                }

                &.featured-post {
                    flex: 0 0 100%;

                    figure.featuredMedia {
                        padding-bottom: 50%;
                    }
                }
                
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    display: block;
                    width: 75px;
                    height: 3px;
                    border-radius: 3px;
                    background-color: $darkBackground;
                    @include animateEaseInOut();
                }

                &:hover {
                    
                    &:after {
                        width: 100px;
                        @include animateEaseInOut();
                    }
                }

                a {
                    @include flex-column(flex-start, stretch, 10px);
                    color: $primaryColor;

                    &:hover {
                        text-decoration: none;
                    }

                    .taxonomy {
                        font-size: 0.8rem;
                        line-height: 0.8rem;
                        color: $topBarMenuColor;
                    }
    
                    h2 {
                        font-size: 1.25rem;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
        .innerDetails {
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            border-top: 1px solid $lightGreyBackgound;
            border-bottom: 1px solid $lightGreyBackgound;
            text-align: center;
            color: $topBarMenuColor;
            font-size: 0.85rem;
        }
    
        // Post content block
        .renderedContent {
            width: 50%;
            margin: 0 auto;
            line-height: 1.5;
            font-size: 1.15rem;
    
            a {
                color: $linkHoverColor;
            }

            figure {
                margin: 0;
                padding: 0;
                width: 100% !important;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

// Gallery styling

.gallery {
    @include flex-row(flex-start, stretch, 10px, wrap);
    width: 100%;

    a {
        display: flex;
        flex: 1 0 calc(33.33% - 20px); // This sets the default to 33.33% width
        padding-bottom: 33.33%;  // This will give the aspect ratio for regular columns
        position: relative;
        overflow: hidden;

        // Every 3n+1 (4th, 7th, 10th...) picture will be targeted IF it's also the last child
        &:nth-child(3n+1):last-child {
            flex: 1 0 100%; // Takes the full width
            padding-bottom: 66%;  // Adjust the padding to maintain the aspect ratio for the full width
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}


// Pagination styles

.pagination {
    @include flex-row(flex-start, center, 10px, wrap);
    padding-top: 30px;
    text-transform: uppercase;
    font-size: 0.9rem;
    color: $primaryColor;
    font-weight: 500;

    a, span {
        padding: 0.5rem 0.75rem;
        line-height: 1rem;
        color: $darkBackground;

        &.activePage, &:hover {
            text-decoration: none;
            background-color: $lightGreyBackgound;
            color: $darkBackground;
            border-radius: 0.5rem;
        }
    }

    .navButton {
        @include flex-row(center, center, 10px ,nowrap);
        color: $primaryColor;
    }
}

// Author Box Styling
.authorBox {
    @include flex-row(flex-start, center, 20px, nowrap);
    width: 100%;
    max-width: 500px;
    font-size: 0.9rem;
    line-height: 1.5;
    color: $topBarMenuColor;
    
    .authorImage {
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 2px solid $secondaryColor-70;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }

    p {
        margin: 0;
    }
}

// Footer styling   
footer {
    @include flex-column(center, center, 40px);
    width: 100%;
    background-color: $lightGreyBackgound;
    padding: 60px 20px;

    .inner {
        @include flex-row(space-between, flex-end, 20px, nowrap);
        width: 100%;
        max-width: $contentWidth;

        .contacts {
            @include flex-column(flex-start, flex-start, 20px);

            .branding {
                padding-bottom: 20px;
                margin-bottom: 10px;
                border-bottom: 1px solid $platinum;
            }
        }
    }

    #footerNav {

        ul {

            @include flex-row(flex-start, center, 20px);
            list-style-type: none;

            li {
                a {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .copy {
        width: 100%;
        max-width: $contentWidth;
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid $platinum;
        text-align: center
    }
}

.banner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px 0 0;
    margin: 10px 0 0;
    border-top: 1px solid #DDE3E1;
    // border-bottom: 1px solid #eef1f0;

    img {
        width: 100%;
        height: auto;
    }

    .show-mobile {
        display: none;
    }

    .show-desktop {
        display: block;
    }

    &:hover {
        cursor: pointer;
    }
}

@import "mobile_layout";