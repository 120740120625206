@media (max-width: $breakpoint-tablet) {

    .App {
        padding-top: 100px;
    }

    // Featured image styles
    figure.featuredMedia {
        padding-bottom: 66%;
    }

    header {
    
        .headerWrapper {
            min-height: 55px;
    
            // Logo/Branding
            a.branding {
                font-size: 1.15rem;
                line-height: 1.5rem;
            }
        
            // Main navigation styles
            nav {
                .icon {
                    display: block;
                    width: 30px;
                    height: 30px;
                    padding: 0;
                    margin: 0;
                    font-size: 1.5rem;
                    line-height: 1.5rem;
                }

                .overlay {
                    position: fixed;
                    display: none;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    background-color: #333333;
                    opacity: 0;
                    transition: opacity 1s ease-in-out, display 1s ease-in-out;

                    &.active {
                        display: block;
                        opacity: 0.2;
                        transition: opacity 1s ease-in-out, display 1s ease-in-out;
                    }
                }

                ul {
                    @include flex-column(center, center, 0px);
                    position: fixed;
                    opacity: 0;
                    top: 0;
                    right: -100vw;
                    width: 100%;
                    max-width: 500px;
                    height: 100vh;
                    background-color: #fafafa;
                    z-index: 11;
                    margin: 0;
                    padding: 10px;
                    @include animateEaseInOut;

                    &.active {
                        opacity: 1;
                        right: 0;
                        @include animateEaseInOut;
                    }
                    li {
                        display: block;
                        width: 80%;
                        text-align: center;
                        padding: 15px 0px;
                        border-bottom: 1px solid $lightGreyBackgound;

                        &:last-of-type {
                            border-bottom: none;
                        }
                    }
                    .closeButton {
                        position: absolute;
                        right: 20px;
                        top: 22.5px;
                    }
                }
            }
        }
    }  

    .page-container {
        @include flex-column(flex-start, center, 20px);
        width: 100%;
        min-height: 60vh;
        padding: 0 20px;
    
        main {
            @include flex-column(center, center, 0);
            width: 100%;
            max-width: $contentWidth;
    
            // Posts block styling
            .posts-block {
                @include flex-row(flex-start, stretch, 10px, wrap);
                width: 100%;
    
                .post {
                    @include flex-column(flex-start, stretch, 10px);
                    flex: 1 0 calc(50% - 10px);
                    padding-bottom: 20px;
                    margin-bottom: 10px;
                    position: relative;

                    &.featured-post {
                        flex: 0 0 100%;
    
                        figure.featuredMedia {
                            padding-bottom: 66%;
                        }
                    }
                    
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0px;
                        left: 0;
                        display: block;
                        width: 75px;
                        height: 3px;
                        border-radius: 3px;
                        background-color: $darkBackground;
                        @include animateEaseInOut();
                    }
    
                    &:hover {
                        
                        &:after {
                            width: 100px;
                            @include animateEaseInOut();
                        }
                    }
    
                    a {
                        color: $primaryColor;
                        @include flex-column(flex-start, stretch, 10px);
    
                        &:hover {
                            text-decoration: none;
                        }
    
                        .taxonomy {
                            font-size: 0.8rem;
                            line-height: 1rem;
                            color: $topBarMenuColor;
                        }
        
                        h2 {
                            font-size: 1rem;
                            line-height: 1.25;
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
            }
            // Post content block
            .renderedContent {
                width: 70%;
                margin: 0 auto;
                font-size: 1.05rem;
            } 
        }
    
    
    }

}

@media (max-width: $breakpoint-mobile) {

    .banner {
    
        .show-mobile {
            display: block;
        }
        .show-desktop {
            display: none;
        }
    }

    .page-container {
        main {
            width: 100%;

            .content-wrapper {
                width: 100%;
            }
            h1,
            h2 {
                line-height: normal;
            }
            h1 {
                font-size: 1.75rem;
            }
        }
    }

    // Post content block
    .renderedContent {
        width:100% !important;
        margin: 0;

        figure {
            margin: 0;
            padding: 0;
            max-width: 100%;
            height: auto;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    // Author Box Styling
    .authorBox {
        @include flex-column(flex-start, center, 20px);
        max-width: 100%;
        text-align: center;
    }

    footer {
        .inner {
            @include flex-column(center, center, 20px);
        }

        .copy {
            margin-top: 0;
        }

        .footerNavigation {
            width: 100%;

            #footerNav {
    
                ul {    
                    @include flex-row(center, center, 20px);
                    list-style-type: none;
                    padding: 0;
        
                    li {
                        // padding: 0 20px 0 0;
    
                        a {
                            font-size: 0.85rem;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }

}