@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600&display=swap');

// Global Fonts
$primaryFont:   "Outfit", sans-serif;
$accentFont:    "cormorant-garamond", serif;

// Global Colors
$primaryColor:        #000000;
$secondaryColor:      #ffffff;
$secondaryColor-70:   #ffffffb3;
$backgroundColor:     $secondaryColor;
$lightGreyBackgound:  #eef1f0;
$platinum:            #DDE3E1;
$textColor:           #333333;
$accentColor:         #f4f2f2;
$yellow:              #ffb800;
$linkHoverColor:      #4c4edd;
$linkHoverColor-70:   #4c4eddb3;
$darkBackground:      #0a1829;
$topBarMenuColor:     #5B6671;

// Content width and breakpoints
$contentWidth: 1280px;
$breakpoint-tablet: 1200px;
$breakpoint-mobile: 768px;